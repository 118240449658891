import { render, staticRenderFns } from "./EliminarCuenta.vue?vue&type=template&id=7770f65b&scoped=true&"
import script from "./EliminarCuenta.vue?vue&type=script&lang=ts&"
export * from "./EliminarCuenta.vue?vue&type=script&lang=ts&"
import style0 from "./EliminarCuenta.vue?vue&type=style&index=0&id=7770f65b&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7770f65b",
  null
  
)

export default component.exports