var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home",staticStyle:{"font-family":"Fira Sans"}},[_c('div',{staticClass:"row flex-center"},[_c('div',{staticClass:"row col-12 justify-center q-mb-md",staticStyle:{"max-width":"1440px !important","margin-left":"auto","margin-right":"auto","font-family":"Fira Sans"}},[_vm._m(0),_c('div',{staticClass:"row col-11 q-mb-xl"},_vm._l((_vm.categorias),function(item){return _c('div',{key:item.id,staticClass:"row col-12 col-sm-4 col-md-3 col-lg-2 col-xl-2 cursor-pointer"},[(item.id == 1)?_c('div',{staticClass:"row justify-center  col-12 q-pa-md",on:{"click":function($event){return _vm.irCategoria(item.id, 'adulto-mayor')}}},[_c('q-card',{staticStyle:{"width":"220px","height":"220px","background-color":"#fafafa","border-radius":"12px"}},[_c('q-card-section',[_c('div',{staticClass:"row justify-center col-12 q-mt-md"},[_c('div',{staticClass:"row justify-center col-6"},[_c('div',{staticStyle:{"width":"100px","height":"100px"}},[_c('img',{staticStyle:{"width":"100% !important","height":"auto !important"},attrs:{"src":'https://s3-us-west-2.amazonaws.com/s3.documentos.tarjetavecino.nunoa/web/categorias/' +
                            item.id +
                            '.png',"alt":""}})]),_c('div',{staticClass:"row justify-center col-12 text-center text-bold q-mt-lg",staticStyle:{"font-size":"16px"}},[_c('p',[_vm._v(_vm._s(item.nombre))])])])])])],1)],1):_vm._e(),(item.id == 2)?_c('div',{staticClass:"row justify-center  col-12 q-pa-md",on:{"click":function($event){return _vm.irCategoria(item.id, 'asesoria-legal')}}},[_c('q-card',{staticStyle:{"width":"220px","height":"220px","background-color":"#fafafa","border-radius":"12px"}},[_c('q-card-section',[_c('div',{staticClass:"row justify-center col-12 q-mt-md"},[_c('div',{staticClass:"row justify-center col-6"},[_c('div',{staticStyle:{"width":"100px","height":"100px"}},[_c('img',{staticStyle:{"width":"100% !important","height":"auto !important"},attrs:{"src":'https://s3-us-west-2.amazonaws.com/s3.documentos.tarjetavecino.nunoa/web/categorias/' +
                            item.id +
                            '.png',"alt":""}})]),_c('div',{staticClass:"row justify-center col-12 text-center text-bold q-mt-lg",staticStyle:{"font-size":"16px"}},[_c('p',[_vm._v(_vm._s(item.nombre))])])])])])],1)],1):_vm._e(),(item.id == 3)?_c('div',{staticClass:"row justify-center  col-12 q-pa-md",on:{"click":function($event){return _vm.irCategoria(item.id, 'automoviles')}}},[_c('q-card',{staticStyle:{"width":"220px","height":"220px","background-color":"#fafafa","border-radius":"12px"}},[_c('q-card-section',[_c('div',{staticClass:"row justify-center col-12 q-mt-md"},[_c('div',{staticClass:"row justify-center col-6"},[_c('div',{staticStyle:{"width":"100px","height":"100px"}},[_c('img',{staticStyle:{"width":"100% !important","height":"auto !important"},attrs:{"src":'https://s3-us-west-2.amazonaws.com/s3.documentos.tarjetavecino.nunoa/web/categorias/' +
                            item.id +
                            '.png',"alt":""}})]),_c('div',{staticClass:"row justify-center col-12 text-center text-bold q-mt-lg",staticStyle:{"font-size":"16px"}},[_c('p',[_vm._v(_vm._s(item.nombre))])])])])])],1)],1):_vm._e(),(item.id == 4)?_c('div',{staticClass:"row justify-center  col-12 q-pa-md",on:{"click":function($event){return _vm.irCategoria(item.id, 'belleza')}}},[_c('q-card',{staticStyle:{"width":"220px","height":"220px","background-color":"#fafafa","border-radius":"12px"}},[_c('q-card-section',[_c('div',{staticClass:"row justify-center col-12 q-mt-md"},[_c('div',{staticClass:"row justify-center col-6"},[_c('div',{staticStyle:{"width":"100px","height":"100px"}},[_c('img',{staticStyle:{"width":"100% !important","height":"auto !important"},attrs:{"src":'https://s3-us-west-2.amazonaws.com/s3.documentos.tarjetavecino.nunoa/web/categorias/' +
                            item.id +
                            '.png',"alt":""}})]),_c('div',{staticClass:"row justify-center col-12 text-center text-bold q-mt-lg",staticStyle:{"font-size":"16px"}},[_c('p',[_vm._v(_vm._s(item.nombre))])])])])])],1)],1):_vm._e(),(item.id == 5)?_c('div',{staticClass:"row justify-center  col-12 q-pa-md",on:{"click":function($event){return _vm.irCategoria(item.id, 'cultura')}}},[_c('q-card',{staticStyle:{"width":"220px","height":"220px","background-color":"#fafafa","border-radius":"12px"}},[_c('q-card-section',[_c('div',{staticClass:"row justify-center col-12 q-mt-md"},[_c('div',{staticClass:"row justify-center col-6"},[_c('div',{staticStyle:{"width":"100px","height":"100px"}},[_c('img',{staticStyle:{"width":"100% !important","height":"auto !important"},attrs:{"src":'https://s3-us-west-2.amazonaws.com/s3.documentos.tarjetavecino.nunoa/web/categorias/' +
                            item.id +
                            '.png',"alt":""}})]),_c('div',{staticClass:"row justify-center col-12 text-center text-bold q-mt-lg",staticStyle:{"font-size":"16px"}},[_c('p',[_vm._v(_vm._s(item.nombre))])])])])])],1)],1):_vm._e(),(item.id == 6)?_c('div',{staticClass:"row justify-center  col-12 q-pa-md",on:{"click":function($event){return _vm.irCategoria(item.id, 'deportes')}}},[_c('q-card',{staticStyle:{"width":"220px","height":"220px","background-color":"#fafafa","border-radius":"12px"}},[_c('q-card-section',[_c('div',{staticClass:"row justify-center col-12 q-mt-md"},[_c('div',{staticClass:"row justify-center col-6"},[_c('div',{staticStyle:{"width":"100px","height":"100px"}},[_c('img',{staticStyle:{"width":"100% !important","height":"auto !important"},attrs:{"src":'https://s3-us-west-2.amazonaws.com/s3.documentos.tarjetavecino.nunoa/web/categorias/' +
                            item.id +
                            '.png',"alt":""}})]),_c('div',{staticClass:"row justify-center col-12 text-center text-bold q-mt-lg",staticStyle:{"font-size":"16px"}},[_c('p',[_vm._v(_vm._s(item.nombre))])])])])])],1)],1):_vm._e(),(item.id == 7)?_c('div',{staticClass:"row justify-center  col-12 q-pa-md",on:{"click":function($event){return _vm.irCategoria(item.id, 'educacion')}}},[_c('q-card',{staticStyle:{"width":"220px","height":"220px","background-color":"#fafafa","border-radius":"12px"}},[_c('q-card-section',[_c('div',{staticClass:"row justify-center col-12 q-mt-md"},[_c('div',{staticClass:"row justify-center col-6"},[_c('div',{staticStyle:{"width":"100px","height":"100px"}},[_c('img',{staticStyle:{"width":"100% !important","height":"auto !important"},attrs:{"src":'https://s3-us-west-2.amazonaws.com/s3.documentos.tarjetavecino.nunoa/web/categorias/' +
                            item.id +
                            '.png',"alt":""}})]),_c('div',{staticClass:"row justify-center col-12 text-center text-bold q-mt-lg",staticStyle:{"font-size":"16px"}},[_c('p',[_vm._v(_vm._s(item.nombre))])])])])])],1)],1):_vm._e(),(item.id == 8)?_c('div',{staticClass:"row justify-center  col-12 q-pa-md",on:{"click":function($event){return _vm.irCategoria(item.id, 'emprendimiento')}}},[_c('q-card',{staticStyle:{"width":"220px","height":"220px","background-color":"#fafafa","border-radius":"12px"}},[_c('q-card-section',[_c('div',{staticClass:"row justify-center col-12 q-mt-md"},[_c('div',{staticClass:"row justify-center col-6"},[_c('div',{staticStyle:{"width":"100px","height":"100px"}},[_c('img',{staticStyle:{"width":"100% !important","height":"auto !important"},attrs:{"src":'https://s3-us-west-2.amazonaws.com/s3.documentos.tarjetavecino.nunoa/web/categorias/' +
                            item.id +
                            '.png',"alt":""}})]),_c('div',{staticClass:"row justify-center col-12 text-center text-bold q-mt-lg",staticStyle:{"font-size":"16px"}},[_c('p',[_vm._v(_vm._s(item.nombre))])])])])])],1)],1):_vm._e(),(item.id == 9 && item.activo == 1)?_c('div',{staticClass:"row justify-center  col-12 q-pa-md",on:{"click":function($event){return _vm.irCategoria(item.id, 'entretencion')}}},[_c('q-card',{staticStyle:{"width":"220px","height":"220px","background-color":"#fafafa","border-radius":"12px"}},[_c('q-card-section',[_c('div',{staticClass:"row justify-center col-12 q-mt-md"},[_c('div',{staticClass:"row justify-center col-6"},[_c('div',{staticStyle:{"width":"100px","height":"100px"}},[_c('img',{staticStyle:{"width":"100% !important","height":"auto !important"},attrs:{"src":'https://s3-us-west-2.amazonaws.com/s3.documentos.tarjetavecino.nunoa/web/categorias/' +
                            item.id +
                            '.png',"alt":""}})]),_c('div',{staticClass:"row justify-center col-12 text-center text-bold q-mt-lg",staticStyle:{"font-size":"16px"}},[_c('p',[_vm._v(_vm._s(item.nombre))])])])])])],1)],1):_vm._e(),(item.id == 10)?_c('div',{staticClass:"row justify-center  col-12 q-pa-md",on:{"click":function($event){return _vm.irCategoria(item.id, 'hogar')}}},[_c('q-card',{staticStyle:{"width":"220px","height":"220px","background-color":"#fafafa","border-radius":"12px"}},[_c('q-card-section',[_c('div',{staticClass:"row justify-center col-12 q-mt-md"},[_c('div',{staticClass:"row justify-center col-6"},[_c('div',{staticStyle:{"width":"100px","height":"100px"}},[_c('img',{staticStyle:{"width":"100% !important","height":"auto !important"},attrs:{"src":'https://s3-us-west-2.amazonaws.com/s3.documentos.tarjetavecino.nunoa/web/categorias/' +
                            item.id +
                            '.png',"alt":""}})]),_c('div',{staticClass:"row justify-center col-12 text-center text-bold q-mt-lg",staticStyle:{"font-size":"16px"}},[_c('p',[_vm._v(_vm._s(item.nombre))])])])])])],1)],1):_vm._e(),(item.id == 11)?_c('div',{staticClass:"row justify-center  col-12 q-pa-md",on:{"click":function($event){return _vm.irCategoria(item.id, 'mascotas')}}},[_c('q-card',{staticStyle:{"width":"220px","height":"220px","background-color":"#fafafa","border-radius":"12px"}},[_c('q-card-section',[_c('div',{staticClass:"row justify-center col-12 q-mt-md"},[_c('div',{staticClass:"row justify-center col-6"},[_c('div',{staticStyle:{"width":"100px","height":"100px"}},[_c('img',{staticStyle:{"width":"100% !important","height":"auto !important"},attrs:{"src":'https://s3-us-west-2.amazonaws.com/s3.documentos.tarjetavecino.nunoa/web/categorias/' +
                            item.id +
                            '.png',"alt":""}})]),_c('div',{staticClass:"row justify-center col-12 text-center text-bold q-mt-lg",staticStyle:{"font-size":"16px"}},[_c('p',[_vm._v(_vm._s(item.nombre))])])])])])],1)],1):_vm._e(),(item.id == 12)?_c('div',{staticClass:"row justify-center  col-12 q-pa-md",on:{"click":function($event){return _vm.irCategoria(item.id, 'municipales')}}},[_c('q-card',{staticStyle:{"width":"220px","height":"220px","background-color":"#fafafa","border-radius":"12px"}},[_c('q-card-section',[_c('div',{staticClass:"row justify-center col-12 q-mt-md"},[_c('div',{staticClass:"row justify-center col-6"},[_c('div',{staticStyle:{"width":"100px","height":"100px"}},[_c('img',{staticStyle:{"width":"100% !important","height":"auto !important"},attrs:{"src":'https://s3-us-west-2.amazonaws.com/s3.documentos.tarjetavecino.nunoa/web/categorias/' +
                            item.id +
                            '.png',"alt":""}})]),_c('div',{staticClass:"row justify-center col-12 text-center text-bold q-mt-lg",staticStyle:{"font-size":"16px"}},[_c('p',[_vm._v(_vm._s(item.nombre))])])])])])],1)],1):_vm._e(),(item.id == 13)?_c('div',{staticClass:"row justify-center  col-12 q-pa-md",on:{"click":function($event){return _vm.irCategoria(item.id, 'niños')}}},[_c('q-card',{staticStyle:{"width":"220px","height":"220px","background-color":"#fafafa","border-radius":"12px"}},[_c('q-card-section',[_c('div',{staticClass:"row justify-center col-12 q-mt-md"},[_c('div',{staticClass:"row justify-center col-6"},[_c('div',{staticStyle:{"width":"100px","height":"100px"}},[_c('img',{staticStyle:{"width":"100% !important","height":"auto !important"},attrs:{"src":'https://s3-us-west-2.amazonaws.com/s3.documentos.tarjetavecino.nunoa/web/categorias/' +
                            item.id +
                            '.png',"alt":""}})]),_c('div',{staticClass:"row justify-center col-12 text-center text-bold q-mt-lg",staticStyle:{"font-size":"16px"}},[_c('p',[_vm._v(_vm._s(item.nombre))])])])])])],1)],1):_vm._e(),(item.id == 14)?_c('div',{staticClass:"row justify-center  col-12 q-pa-md",on:{"click":function($event){return _vm.irCategoria(item.id, 'gastronomia')}}},[_c('q-card',{staticStyle:{"width":"220px","height":"220px","background-color":"#fafafa","border-radius":"12px"}},[_c('q-card-section',[_c('div',{staticClass:"row justify-center col-12 q-mt-md"},[_c('div',{staticClass:"row justify-center col-6"},[_c('div',{staticStyle:{"width":"100px","height":"100px"}},[_c('img',{staticStyle:{"width":"100% !important","height":"auto !important"},attrs:{"src":'https://s3-us-west-2.amazonaws.com/s3.documentos.tarjetavecino.nunoa/web/categorias/' +
                            item.id +
                            '.png',"alt":""}})]),_c('div',{staticClass:"row justify-center row justify-center col-12 text-center text-bold q-mt-lg",staticStyle:{"font-size":"16px"}},[_c('p',[_vm._v(_vm._s(item.nombre))])])])])])],1)],1):_vm._e(),(item.id == 15)?_c('div',{staticClass:"row justify-center  col-12 q-pa-md",on:{"click":function($event){return _vm.irCategoria(item.id, 'salud')}}},[_c('q-card',{staticStyle:{"width":"220px","height":"220px","background-color":"#fafafa","border-radius":"12px"}},[_c('q-card-section',[_c('div',{staticClass:"row justify-center col-12 q-mt-md"},[_c('div',{staticClass:"row justify-center col-6"},[_c('div',{staticStyle:{"width":"100px","height":"100px"}},[_c('img',{staticStyle:{"width":"100% !important","height":"auto !important"},attrs:{"src":'https://s3-us-west-2.amazonaws.com/s3.documentos.tarjetavecino.nunoa/web/categorias/' +
                            item.id +
                            '.png',"alt":""}})]),_c('div',{staticClass:"row justify-center col-12 text-center text-bold q-mt-lg",staticStyle:{"font-size":"16px"}},[_c('p',[_vm._v(_vm._s(item.nombre))])])])])])],1)],1):_vm._e(),(item.id == 16)?_c('div',{staticClass:"row justify-center  col-12 q-pa-md",on:{"click":function($event){return _vm.irCategoria(item.id, 'seguridad')}}},[_c('q-card',{staticStyle:{"width":"220px","height":"220px","background-color":"#fafafa","border-radius":"12px"}},[_c('q-card-section',[_c('div',{staticClass:"row justify-center col-12 q-mt-md"},[_c('div',{staticClass:"row justify-center col-6"},[_c('div',{staticStyle:{"width":"100px","height":"100px"}},[_c('img',{staticStyle:{"width":"100% !important","height":"auto !important"},attrs:{"src":'https://s3-us-west-2.amazonaws.com/s3.documentos.tarjetavecino.nunoa/web/categorias/' +
                            item.id +
                            '.png',"alt":""}})]),_c('div',{staticClass:"row justify-center col-12 text-center text-bold q-mt-lg",staticStyle:{"font-size":"16px"}},[_c('p',[_vm._v(_vm._s(item.nombre))])])])])])],1)],1):_vm._e(),(item.id == 17)?_c('div',{staticClass:"row justify-center  col-12 q-pa-md",on:{"click":function($event){return _vm.irCategoria(item.id, 'seguros')}}},[_c('q-card',{staticStyle:{"width":"220px","height":"220px","background-color":"#fafafa","border-radius":"12px"}},[_c('q-card-section',[_c('div',{staticClass:"row justify-center col-12 q-mt-md"},[_c('div',{staticClass:"row justify-center col-6"},[_c('div',{staticStyle:{"width":"100px","height":"100px"}},[_c('img',{staticStyle:{"width":"100% !important","height":"auto !important"},attrs:{"src":'https://s3-us-west-2.amazonaws.com/s3.documentos.tarjetavecino.nunoa/web/categorias/' +
                            item.id +
                            '.png',"alt":""}})]),_c('div',{staticClass:"row justify-center col-12 text-center text-bold q-mt-lg",staticStyle:{"font-size":"16px"}},[_c('p',[_vm._v(_vm._s(item.nombre))])])])])])],1)],1):_vm._e(),(item.id == 18)?_c('div',{staticClass:"row justify-center  col-12 q-pa-md",on:{"click":function($event){return _vm.irCategoria(item.id, 'tecnologia')}}},[_c('q-card',{staticStyle:{"width":"220px","height":"220px","background-color":"#fafafa","border-radius":"12px"}},[_c('q-card-section',[_c('div',{staticClass:"row justify-center col-12 q-mt-md"},[_c('div',{staticClass:"row justify-center col-6"},[_c('div',{staticStyle:{"width":"100px","height":"100px"}},[_c('img',{staticStyle:{"width":"100% !important","height":"auto !important"},attrs:{"src":'https://s3-us-west-2.amazonaws.com/s3.documentos.tarjetavecino.nunoa/web/categorias/' +
                            item.id +
                            '.png',"alt":""}})]),_c('div',{staticClass:"row justify-center col-12 text-center text-bold q-mt-lg",staticStyle:{"font-size":"16px"}},[_c('p',[_vm._v(_vm._s(item.nombre))])])])])])],1)],1):_vm._e(),(item.id == 19)?_c('div',{staticClass:"row justify-center  col-12 q-pa-md",on:{"click":function($event){return _vm.irCategoria(item.id, 'turismo')}}},[_c('q-card',{staticStyle:{"width":"220px","height":"220px","background-color":"#fafafa","border-radius":"12px"}},[_c('q-card-section',[_c('div',{staticClass:"row justify-center col-12 q-mt-md"},[_c('div',{staticClass:"row justify-center col-6"},[_c('div',{staticStyle:{"width":"100px","height":"100px"}},[_c('img',{staticStyle:{"width":"100% !important","height":"auto !important"},attrs:{"src":'https://s3-us-west-2.amazonaws.com/s3.documentos.tarjetavecino.nunoa/web/categorias/' +
                            item.id +
                            '.png',"alt":""}})]),_c('div',{staticClass:"row justify-center col-12  text-bold q-mt-md",staticStyle:{"font-size":"16px"}},[_c('p',[_vm._v(_vm._s(item.nombre))])])])])])],1)],1):_vm._e(),(item.id == 20)?_c('div',{staticClass:"row justify-center  col-12 q-pa-md",on:{"click":function($event){return _vm.irCategoria(item.id, 'servicios')}}},[_c('q-card',{staticStyle:{"width":"220px","height":"220px","background-color":"#fafafa","border-radius":"12px"}},[_c('q-card-section',[_c('div',{staticClass:"row justify-center col-12 q-mt-md"},[_c('div',{staticClass:"row justify-center col-6"},[_c('div',{staticStyle:{"width":"100px","height":"100px"}},[_c('img',{staticStyle:{"width":"100% !important","height":"auto !important"},attrs:{"src":'https://s3-us-west-2.amazonaws.com/s3.documentos.tarjetavecino.nunoa/web/categorias/' +
                            item.id +
                            '.png',"alt":""}})]),_c('div',{staticClass:"row justify-center col-12 text-center text-bold q-mt-lg",staticStyle:{"font-size":"16px"}},[_c('p',[_vm._v(_vm._s(item.nombre))])])])])])],1)],1):_vm._e()])}),0)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row justify-center col-12 q-my-lg"},[_c('label',{staticClass:"page_title"},[_vm._v("Convenios")])])}]

export { render, staticRenderFns }