import { render, staticRenderFns } from "./LoginComponent.vue?vue&type=template&id=2315c181&scoped=true&"
import script from "./LoginComponent.vue?vue&type=script&lang=ts&"
export * from "./LoginComponent.vue?vue&type=script&lang=ts&"
import style0 from "./LoginComponent.vue?vue&type=style&index=0&id=2315c181&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2315c181",
  null
  
)

export default component.exports