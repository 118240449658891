import { render, staticRenderFns } from "./AssociateCard.vue?vue&type=template&id=6f890094&scoped=true&"
import script from "./AssociateCard.vue?vue&type=script&lang=ts&"
export * from "./AssociateCard.vue?vue&type=script&lang=ts&"
import style0 from "./AssociateCard.vue?vue&type=style&index=0&id=6f890094&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f890094",
  null
  
)

export default component.exports